<template>
  <div class="main">
    <a-layout class="mlayout">
      <a-layout>
        <a-layout-content class="pc indexLayoutContent" style="padding: 0px">
          <div
            class="index page flex-col justify-start"
            style="position: fixed"
          >
            <top-a
              ref="topa"
              :kind="`index`"
              :topbarhide="topbarhide"
              :tb="tb"
              :w750="w750"
              :topbarOpacity="topbarOpacity"
              :lag="lag"
              :bmshow="bmshow"
              @bmclose="bmclose"
              :bmstate="bmstate"
              :bmid="bmid"
              :bkind="bkind"
              @rlstate="rlstate"
              :selectlag ="1"
            ></top-a>

            <div class="mswiper windowheight">
              <div class="swiper sc">
                <div :class="`swiper-wrapper`">
                  <div
                    :class="`swiper-slide ssm ssm0`"
                    style="flex-direction: column"
                  >
                    <div
                      class="swiper lsc-1"
                      style="position: relative; top: 0px"
                    >
                      <div class="swiper-wrapper">
                        <div class="swiper-slide">
                          <div
                            class="group_1 flex-col windowheight"
                            style="background-color: rgba(38, 56, 23, 1)"
                          >
                            <div
                              v-if="clist90.length > 0"
                              class="swiper sc0"
                              :style="`background-image:url(${swiper0backimg});opacity:${
                                1 - opy1
                              };opacity: 0;`"
                            >
                              <div class="swiper-wrapper">
                                <template v-for="(item, index) in clist90">
                                  <div
                                    :class="`swiper-slide ssl0 ss${index}`"
                                    :key="index"
                                  >
                                    <div
                                      :class="`mask mk0 ${
                                        index == 0 ? 'mkmaskshow' : ''
                                      }`"
                                      :style="`background-image:url(${
                                        baseUrl + item.imgurl
                                      });`"
                                      @click="
                                        () => {
                                          godetall(
                                            item.url,
                                            item.pname,
                                            item.kind,
                                            item.id
                                          );
                                        }
                                      "
                                    ></div>
                                  </div>
                                </template>
                              </div>
                            </div>

                            <div class="mkdots">
                              <template v-for="(item, index) in clist90">
                                <div
                                  :key="index"
                                  :class="`md ${
                                    mdselectIndex == index ? 'mdselected' : ''
                                  }`"
                                ></div>
                              </template>
                            </div>
                          </div>

                          <div class="group_2 flex-row justify-center">
                            <div class="group_2_in flex-row justify-between">
                              <div class="group_2_1 flex-col">
                                <div
                                  class="textitem"
                                  style="
                                    margin-top: 25rem;
                                    border-top: 1px solid #ccc;
                                  "
                                ></div>
                                <div class="textitem" style="margin-top: 4rem">
                                  All
                                  Fields趣野无限，一个致力于探索户外生活方式的文化传播交流平台。
                                  <br />
                                  <br />
                                  我们特设「<span class="bt">趣野CLUB</span
                                  >」、「<span class="bt">趣野好物</span
                                  >」、「<span class="bt">趣野风向</span
                                  >」等入口，引领多元户外生活体验、整合各圈层户外品牌、集结户外潮流文化动向，从活动、商品、资讯等多维度，为喜爱户外的人群提供一站式、可持续、个性化的户外生活方式解决方案。
                                  <br />
                                  <br />
                                </div>
                                <div
                                  class="lmore flex-row justify-center"
                                  style="opacity: 0"
                                >
                                  <div class="lmorein flex-row justify-between">
                                    <span class="text-group_123">了解更多</span>
                                    <img
                                      referrerpolicy="no-referrer"
                                      src="https://cdn.moheweb.com/vdb/lweb/imgs/bj.png"
                                      class="image_186"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="group_2_2 flex-col">
                                <span class="text_12">
                                  <img
                                    src="https://cdn.moheweb.com/vdb/lweb/imgs/allfields1.png"
                                    style="width: 82.9rem; height: 12.8rem"
                                  />
                                </span>
                              </div>
                            </div>
                            <!-- <div
                              class="backimg"
                              style="
                                background-size: 100%;
                                background-repeat: no-repeat;
                                background-position: 100% -40%;
                                background-attachment: fixed;
                                opacity: 0.1;
                              "
                            ></div> -->
                            <div class="backimg" style="opacity: 0.1; background-position: right 80%; background-attachment: local;"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div :class="`swiper-slide  ssm ssm1`">
                    <div class="group_3 flex-col windowheight">
                      <div class="g3 flex-col" :style="`opacity:${1 - opy3};`">
                        <div class="g3in flex-col">
                          <div class="gbar flex-row justify-between">
                            <div class="gtitles flex-col">
                              <span data-v-3f1e1d78="" class="text_13">
                                <img
                                  src="https://cdn.moheweb.com/vdb/lweb/imgs/title4.png"
                                  style="width: 368px; height: 78px"
                                />
                              </span>
                              <span data-v-3f1e1d78="" class="text_14"
                                >当户外成为生活常态</span
                              >
                            </div>

                            <div class="btns flex-row justify-end">
                              <router-link :to="`/club/0/${lag}`">
                                <div
                                  class="lbtn flex-row justify-center"
                                  style="margin-right: 4rem"
                                >
                                  <div class="lbtnin flex-row justify-between">
                                    <span class="text-group_123">了解更多</span>

                                    <img
                                      data-v-3f1e1d78=""
                                      referrerpolicy="no-referrer"
                                      src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                      class="image_186"
                                    />
                                  </div>
                                </div>
                              </router-link>

                              <router-link :to="`/clublist/0/${lag}`">
                                <div class="lbtn flex-row justify-center">
                                  <div class="lbtnin flex-row justify-between">
                                    <span class="text-group_123">报名通道</span>

                                    <img
                                      data-v-3f1e1d78=""
                                      referrerpolicy="no-referrer"
                                      src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                      class="image_186"
                                    />
                                  </div>
                                </div>
                              </router-link>
                            </div>
                          </div>

                          <div
                            class="swiper lsc0"
                            style="position: absolute; top: 0px"
                          >
                            <div class="swiper-wrapper">
                              <div class="swiper-slide">
                                <div
                                  class="plist m0 moveing"
                                  data-ystart="500"
                                  data-yfinal="-500"
                                  data-dur="3000"
                                  style="top: 500px"
                                >
                                  <template v-for="(item, index) in clist40">
                                    <div
                                      :class="`pitem ${
                                        mselectindex == 1 ? 'showpitem' : ''
                                      }`"
                                      :style="`background-image: url('${
                                        baseUrl + item.imgurl
                                      }');`"
                                      :key="index"
                                      v-if="index == 0"
                                    >
                                      <div
                                        class="pinfo"
                                        @click="
                                          () => {
                                            godetall(
                                              item.url,
                                              item.pname,
                                              item.kind,
                                              item.id
                                            );
                                          }
                                        "
                                      >
                                        <div class="micon"></div>
                                        <div
                                          class="ptitle"
                                          v-html="item.pname"
                                        ></div>
                                        <div
                                          class="pdes"
                                          v-html="item.des"
                                        ></div>
                                      </div>
                                    </div>
                                    <div
                                      :class="`pitem ${
                                        mselectindex == 1 ? 'showpitem' : ''
                                      }`"
                                      :style="`background-image: url('${
                                        baseUrl + item.imgurl
                                      }');transform: scale(0.65);right: 0rem;`"
                                      :key="index"
                                      v-if="index == 1"
                                    >
                                      <div
                                        class="pinfo"
                                        @click="
                                          () => {
                                            godetall(
                                              item.url,
                                              item.pname,
                                              item.kind,
                                              item.id
                                            );
                                          }
                                        "
                                      >
                                        <div class="micon"></div>
                                        <div
                                          class="ptitle"
                                          v-html="item.pname"
                                        ></div>
                                        <div
                                          class="pdes"
                                          v-html="item.des"
                                        ></div>
                                      </div>
                                    </div>

                                    <div
                                      :class="`pitem ${
                                        mselectindex == 1 ? 'showpitem' : ''
                                      }`"
                                      :style="`background-image:url('${
                                        baseUrl + item.imgurl
                                      }'); transform: scale(.65); left: 40rem; top: 56rem;}`"
                                      :key="index"
                                      v-if="index == 2"
                                    >
                                      <div
                                        class="pinfo"
                                        @click="
                                          () => {
                                            godetall(
                                              item.url,
                                              item.pname,
                                              item.kind,
                                              item.id
                                            );
                                          }
                                        "
                                      >
                                        <div class="micon"></div>
                                        <div
                                          class="ptitle"
                                          v-html="item.pname"
                                        ></div>
                                        <div
                                          class="pdes"
                                          v-html="item.des"
                                        ></div>
                                      </div>
                                    </div>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="zsqlist">
                            <div class="zsqitem zi0 zsqselected"></div>
                            <div class="zsqitem zi1"></div>
                            <div class="zsqitem zi2"></div>
                            <div class="zsqitem zi3"></div>
                            <div class="zsqitem zi4"></div>
                            <div class="zsqitem zi5"></div>
                            <div class="zsqitem zi6"></div>

                            <div class="icon_arrow_botttom"></div>
                          </div>
                        </div>
                        <div class="backimg"></div>
                        <div class="blackimg"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    :class="`swiper-slide  ssm ssm2`"
                    style="background-color: rgba(38, 56, 23, 1)"
                  >
                    <!-- <div
                      class="gbar gbar_4 flex-row justify-between"
                      style="position: absolute; top: 0px"
                    >
                      <div class="gtitles flex-col m1">
                        <span data-v-3f1e1d78="" class="text_13">
                          <img
                            src="https://cdn.moheweb.com/vdb/lweb/imgs/title3.png"
                            style="width: 410px; height: 78px"
                          />
                        </span>
                        <span data-v-3f1e1d78="" class="text_14"
                          >一起来期待ALL FIELDS 趣野无限的首次正式亮相</span
                        >
                      </div>

                      <div class="gdes flex-col"></div>
                    </div> -->
                    <div
                      class="swiper lsc1"
                      style="position: absolute; top: 0px"
                    >
                      <div class="swiper-wrapper">
                        <div class="swiper-slide">
                          <div class="group_4">
                            <div class="g4 flex-col">
                              <div class="g4in flex-col">
                                <div
                                  class="gbar flex-row justify-between gbar_4"
                                  style="position: relative; top: 0px"
                                >
                                  <!-- <div class="gtitles flex-col m1"></div> -->

                                  <div class="gtitles flex-col m1">
                                    <span data-v-3f1e1d78="" class="text_13">
                                      <img
                                        src="https://cdn.moheweb.com/vdb/lweb/imgs/title3.png"
                                        style="width: 410px; height: 78px"
                                      />
                                    </span>
                                    <span data-v-3f1e1d78="" class="text_14"
                                      >一起来期待ALL FIELDS
                                      趣野无限的首次正式亮相</span
                                    >
                                  </div>

                                  <div class="gdes flex-col">
                                    <div
                                      class="textline"
                                      style="margin-top: 13rem"
                                    >
                                      <template v-if="data33 != null">
                                        <span
                                          v-html="
                                            data33.des.replace(/\n/g, '<br />')
                                          "
                                        >
                                        </span>
                                      </template>
                                    </div>

                                    <div class="btns flex-row justify-start">
                                      <div
                                        class="lbtn flex-row justify-center"
                                        style="margin-right: 4rem"
                                        @click="
                                          () => {
                                            //bmopen(data33.id);
                                            bmopen();
                                          }
                                        "
                                      >
                                        <div
                                          class="lbtnin flex-row justify-between"
                                        >
                                          <span class="text-group_123"
                                            >展商通道</span
                                          >

                                          <img
                                            data-v-3f1e1d78=""
                                            referrerpolicy="no-referrer"
                                            src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                            class="image_186"
                                          />
                                        </div>
                                      </div>

                                      <div class="lbtn flex-row justify-center">
                                        <div
                                          class="lbtnin flex-row justify-between"
                                          @click="
                                            () => {
                                              godetall(
                                                data33.url,
                                                data33.pname,
                                                data33.kind,
                                                data33.id
                                              );
                                            }
                                          "
                                        >
                                          <span class="text-group_123"
                                            >观众通道</span
                                          >

                                          <img
                                            data-v-3f1e1d78=""
                                            referrerpolicy="no-referrer"
                                            src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                            class="image_186"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <!--展会页头-->

                                <!--展会列表-->

                                <div class="zlist flex-col">
                                  <template v-for="(item, index) in clist31">
                                    <div
                                      class="zitem flex-row justify-between"
                                      :key="index"
                                      @click="
                                        () => {
                                          godetall(
                                            item.url,
                                            item.pname,
                                            item.kind,
                                            item.id
                                          );
                                        }
                                      "
                                      @mouseover="
                                        () => {
                                          clist31itemover(index);
                                        }
                                      "
                                    >
                                      <!-- 
                                      @mouseleave="
                                        () => {
                                          clist31itemleave();
                                        }
                                      " -->
                                      <div class="zt">
                                        <div class="title">
                                          {{ item.pname }}
                                        </div>
                                        <div class="des">{{ item.des }}</div>
                                      </div>
                                      <div class="zb">
                                        <div class="detall">了解详情</div>
                                      </div>

                                      <div class="ibackcolor"></div>
                                    </div>
                                  </template>

                                  <div
                                    class="clist31img"
                                    v-if="cl31selectindex >= 0"
                                  >
                                    <img
                                      referrerpolicy="no-referrer"
                                      class="imgshow"
                                      :src="
                                        clist31[cl31selectindex].imgurl.indexOf(
                                          'https://'
                                        ) == -1
                                          ? baseUrl +
                                            clist31[cl31selectindex].imgurl
                                          : clist31[cl31selectindex].imgurl
                                      "
                                    />
                                  </div>
                                </div>

                                <div
                                  class="lbtn flex-row justify-center"
                                  style="
                                    margin-left: 8rem;
                                    margin-top: 60px;
                                    margin-bottom: 200px;
                                  "
                                >
                                  <router-link :to="`/show/0/${lag}`">
                                    <div
                                      class="lbtnin flex-row justify-between"
                                    >
                                      <span class="text-group_123"
                                        >了解更多</span
                                      >
                                      <img
                                        data-v-3f1e1d78=""
                                        referrerpolicy="no-referrer"
                                        src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                        class="image_186"
                                      />
                                    </div>
                                  </router-link>
                                </div>
                              </div>
                              <!-- <div class="backimg"></div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="backimg"></div>
                  </div>
                  <div :class="`swiper-slide  ssm ssm3`">
                    <div class="group_5 flex-col windowheight">
                      <div class="g5 flex-col">
                        <div class="g5in flex-col">
                          <div class="gbar gbar_5 flex-row justify-between">
                            <div class="gtitles flex-col">
                              <span
                                data-v-3f1e1d78=""
                                class="text_13"
                                style="color: rgba(50, 50, 50, 1)"
                              >
                                <img
                                  src="https://cdn.moheweb.com/vdb/lweb/imgs/title2.png"
                                  style="width: 328px; height: 78px"
                                />
                              </span>
                              <span
                                data-v-3f1e1d78=""
                                class="text_14"
                                style="color: rgba(50, 50, 50, 1)"
                                >ALL FIELDS让你「闭眼」购物~</span
                              >
                            </div>

                            <div class="btns flex-row justify-end">
                              <div
                                class="lmore flex-row justify-center"
                                style="
                                  margin-top: 0px;
                                  margin-bottom: 0px;
                                  opacity: 0;
                                "
                              >
                                <div class="lmorein flex-row justify-between">
                                  <span class="text-group_123">更多好物</span>

                                  <img
                                    referrerpolicy="no-referrer"
                                    src="https://cdn.moheweb.com/vdb/lweb/imgs/bj.png"
                                    class="image_186 bjt"
                                  />

                                  <img
                                    referrerpolicy="no-referrer"
                                    src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                    class="image_186 wjt"
                                    style="display: none"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="swiper sc1">
                            <div class="swiper-wrapper">
                              <template v-for="(item, index) in clist20">
                                <div
                                  :class="`swiper-slide ss${index}`"
                                  :key="index"
                                >
                                  <div class="ppitem">
                                    <div class="ppinfo">
                                      <div
                                        class="pptitle"
                                        v-html="item.pname"
                                      ></div>
                                      <div
                                        class="ppdes"
                                        v-html="item.des"
                                      ></div>
                                      <div
                                        class="ppprice"
                                        v-if="item.price != '0'"
                                      >
                                        ￥{{ takeprice(item.price) }}
                                      </div>
                                      <div
                                        class="ppbtn"
                                        v-if="item.price != '0'"
                                      >
                                        <a target="_blank" :href="item.url">
                                          <div
                                            class="lmore flex-row justify-center"
                                            style="
                                              margin-top: 0px;
                                              margin-bottom: 0px;
                                              margin-top: 48px;
                                            "
                                          >
                                            <div
                                              class="lmorein flex-row justify-between"
                                            >
                                              <span class="text-group_123"
                                                >立即购买</span
                                              >

                                              <img
                                                referrerpolicy="no-referrer"
                                                src="https://cdn.moheweb.com/vdb/lweb/imgs/bj.png"
                                                class="image_186 bjt"
                                              />

                                              <img
                                                referrerpolicy="no-referrer"
                                                src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                                class="image_186 wjt"
                                                style="display: none"
                                              />
                                            </div>
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                    <div class="ppimg">
                                      <img :src="baseUrl + item.imgurl" />
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                            <!-- Add Pagination -->
                            <div class="pndiv">
                              <div class="toleft" @click="swiper1Prev"></div>
                              <div class="swiper-pagination"></div>
                              <div class="toright" @click="swiper1Next"></div>
                            </div>
                          </div>
                        </div>
                        <div class="backimg"></div>
                      </div>

                      <div
                        class="wzd2"
                        v-if="
                          winHeight >
                          group1h + group2h + group3h + group4h + 4000 + 4000
                        "
                        :style="`opacity:${opy5};`"
                      ></div>
                    </div>
                  </div>

                  <div :class="`swiper-slide  ssm ssm4`">
                    <div
                      class="swiper lsc2"
                      style="position: absolute; top: 0px"
                    >
                      <div class="swiper-wrapper">
                        <div class="swiper-slide">
                          <div
                            class="group_6 flex-col"
                            :style="`-webkit-mask-size: ${masksize}%; ${
                              masksize == 300 ? '-webkit-mask:none;' : ''
                            } opacity:${1 - opy6}; `"
                          >
                            <div class="g6gg">
                              <div class="swiper sc6">
                                <div class="swiper-wrapper">
                                  <template v-for="(item, index) in clist91">
                                    <div :class="`swiper-slide`" :key="index">
                                      <div
                                        class="ggimg"
                                        :style="`background-image:url(${
                                          baseUrl + item.imgurl
                                        });`"
                                        @click="
                                          () => {
                                            godetall(
                                              item.url,
                                              item.pname,
                                              item.kind,
                                              item.id
                                            );
                                          }
                                        "
                                      ></div>
                                    </div>
                                  </template>
                                </div>

                                <div class="swiper-pagination sp6"></div>
                              </div>
                            </div>

                            <div class="g6 flex-col">
                              <div class="g6in flex-col">
                                <!--朋友圈页头-->
                                <div
                                  class="gbar flex-col justify-between gbar_6"
                                >
                                  <div class="gtitles flex-col m1">
                                    <span
                                      data-v-3f1e1d78=""
                                      class="text_13 flex-row justify-end"
                                      style="width: auto; text-align: right"
                                    >
                                      <img
                                        src="https://cdn.moheweb.com/vdb/lweb/imgs/title1.png"
                                        style="
                                          width: 331px;
                                          height: 78px;
                                          margin-right: 110px;
                                        "
                                      />
                                    </span>
                                    <span
                                      data-v-3f1e1d78=""
                                      class="text_14 justify-end"
                                      style="width: auto; text-align: right"
                                      >听说……这些都是ALL FIELDS的好朋友</span
                                    >
                                  </div>

                                  <div
                                    class="gdes flex-row justify-between"
                                    style="width: 100%"
                                  >
                                    <div
                                      class="textline"
                                      style="margin-top: 1rem; width: 64rem"
                                    >
                                      <div
                                        class="tline"
                                        style="
                                          position: relative;
                                          width: 100%;
                                          border-bottom: 1px solid #aaa;
                                          margin-bottom: 20px;
                                        "
                                      ></div>
                                      户外博主们的日常生活是怎么样的？
                                      <br />
                                      户外品牌主理人每天都在忙什么？
                                      <br />
                                      户外专业大咖是怎样练成的？
                                    </div>

                                    <div
                                      class="btns flex-row justify-between g6btns"
                                    >
                                      <router-link :to="`/huoban/0/${lag}`">
                                        <div
                                          class="lmore flex-row justify-center"
                                          style="
                                            margin-top: 0px;
                                            margin-bottom: 0px;
                                            width: 160px;
                                            opacity: 1;
                                          "
                                        >
                                          <div
                                            class="lmorein flex-row justify-between"
                                          >
                                            <span class="text-group_123"
                                              >了解更多</span
                                            >

                                            <img
                                              referrerpolicy="no-referrer"
                                              src="https://cdn.moheweb.com/vdb/lweb/imgs/bj.png"
                                              class="image_186 bjt"
                                            />

                                            <img
                                              referrerpolicy="no-referrer"
                                              src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                              class="image_186 wjt"
                                              style="display: none"
                                            />
                                          </div>
                                        </div>
                                      </router-link>
                                    </div>
                                  </div>
                                </div>

                                <div class="g6plist">
                                  <div
                                    class="pitem p6pi1"
                                    v-if="flist10.length > 0"
                                    :style="`
                                      background-image: url('${baseUrl}${flist10[0].imgurl}');
                                    `"
                                  >
                                    <router-link :to="`/huoban/0/${lag}`">
                                      <div class="pinfo">
                                        <div class="micon"></div>
                                        <div
                                          class="title"
                                          v-html="`@${flist10[0].title}`"
                                        ></div>
                                      </div>
                                    </router-link>
                                  </div>

                                  <span data-v-3f1e1d78="" class="text_49"
                                    >快来这里看看All&nbsp;Fields朋友圈的故事，他们并非一定是你仰望的对象，而是身边的你我他。</span
                                  >

                                  <div
                                    class="pitem p6pi2"
                                    v-if="flist10.length > 1"
                                    :style="`
                                      background-image: url('${baseUrl}${flist10[1].imgurl}');
                                    `"
                                  >
                                    <router-link :to="`/huoban/0/${lag}`">
                                      <div class="pinfo">
                                        <div class="micon"></div>
                                        <div
                                          class="title"
                                          v-html="`@${flist10[1].title}`"
                                        ></div>
                                      </div>
                                    </router-link>
                                  </div>

                                  <div
                                    class="pitem p6pi3"
                                    v-if="flist10.length > 2"
                                    :style="`
                                      background-image: url('${baseUrl}${flist10[2].imgurl}');
                                    `"
                                  >
                                    <router-link :to="`/huoban/0/${lag}`">
                                      <div class="pinfo">
                                        <div class="micon"></div>
                                        <div
                                          class="title"
                                          v-html="`@${flist10[2].title}`"
                                        ></div>
                                      </div>
                                    </router-link>
                                  </div>
                                </div>

                                <div
                                  class="g6plist"
                                  style="
                                    height: 30rem;
                                    border-bottom: 2px solid #ccc;
                                  "
                                >
                                  <div
                                    class="pitem p6pi4"
                                    v-if="flist10.length > 3"
                                    :style="`
                                      background-image: url('${baseUrl}${flist10[3].imgurl}');
                                    `"
                                  >
                                    <router-link :to="`/huoban/0/${lag}`">
                                      <div class="pinfo">
                                        <div class="micon"></div>
                                        <div
                                          class="title"
                                          v-html="`@${flist10[3].title}`"
                                        ></div>
                                      </div>
                                    </router-link>
                                  </div>

                                  <div
                                    class="pitem p6pi5"
                                    v-if="flist10.length > 4"
                                    :style="`
                                      background-image: url('${baseUrl}${flist10[4].imgurl}');
                                    `"
                                  >
                                    <router-link :to="`/huoban/0/${lag}`">
                                      <div class="pinfo">
                                        <div class="micon"></div>
                                        <div
                                          class="title"
                                          v-html="`@${flist10[4].title}`"
                                        ></div>
                                      </div>
                                    </router-link>
                                  </div>
                                </div>
                                <div class="logolist">
                                  <template v-for="(item, index) in flist11">
                                    <img
                                      :key="index"
                                      class="litem"
                                      :src="baseUrl + item.imgurl"
                                      @click="
                                        () => {
                                          setlogoindex(index);
                                        }
                                      "
                                    />
                                  </template>
                                </div>

                                <div class="g6btitle"></div>
                              </div>
                              <div class="backimg"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div :class="`swiper-slide  ssm ssm5`">
                    <div class="group_7 flex-col windowheight">
                      <div class="g7 flex-col">
                        <div class="g7in flex-col">
                          <div class="g7list">
                            <template v-for="(item, index) in lslist">
                              <div
                                :class="`g7item flex-col ${
                                  g7selectindex == index ? 'g7itemhover' : ''
                                }`"
                                :style="`${
                                  index == 0
                                    ? 'background-color: rgba(194, 194, 194, 1)'
                                    : ''
                                } ${
                                  index == 1
                                    ? 'background-color: rgba(216, 216, 216, 1)'
                                    : ''
                                } ${
                                  index == 2
                                    ? 'background-color: rgba(238, 238, 238, 1)'
                                    : ''
                                }`"
                                :key="index"
                                @mouseover="
                                  () => {
                                    g7itemover(index);
                                  }
                                "
                                @click="
                                  () => {
                                    gotourl('fengxiang', item.kind);
                                  }
                                "
                              >
                                <div class="micon"></div>
                                <div class="g7itemtext" v-html="item.des"></div>
                                <div class="g7iteminfo">
                                  <div
                                    class="g7itemtitle"
                                    v-html="item.title"
                                  ></div>
                                  <!-- <div class="g7itemdes" v-html="item.title2"></div> -->
                                  <img
                                    referrerpolicy="no-referrer"
                                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga3a5b20e0324082a486419c2b193dbce68f3a2cd2b92053dcbe69c15171ccc1e"
                                    class="image_jt"
                                  />
                                  <img
                                    referrerpolicy="no-referrer"
                                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4e043656295421bba5a24fd6b62f055c22039be9e721c037df2d8ce3f02df8ed"
                                    class="image_jtf"
                                  />
                                </div>
                                <div
                                  class="bimg"
                                  :style="`background-image: url(${item.imgurl});`"
                                ></div>
                              </div>
                            </template>

                            <div class="g7itemf flex-col">
                              <div
                                class="gbar gbar_7 flex-col justify-between"
                                style="margin: 100px"
                              >
                                <div class="gtitles flex-col m1">
                                  <span
                                    data-v-3f1e1d78=""
                                    class="text_13"
                                    style="color: "
                                  >
                                    <img
                                      src="https://cdn.moheweb.com/vdb/lweb/imgs/title0.png"
                                      style="width: 325px; height: 78px"
                                    />
                                  </span>
                                  <span data-v-3f1e1d78="" class="text_14"
                                    >更多视角更多脑洞突破想象</span
                                  >
                                </div>

                                <div class="gdes flex-col">
                                  <img
                                    class="image_505"
                                    referrerpolicy="no-referrer"
                                    style="
                                      width: 100%;
                                      height: 1px;
                                      margin: 126px 0 0 0;
                                    "
                                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng378c9d7e85ae34a74b059b6e2ca95f758374832a11efecbf119443472d4a2b08"
                                  />
                                  <div
                                    class="textline"
                                    style="margin-top: 20px; width: 100%"
                                  >
                                    涵盖热点趋势、亮点单品、兴趣清单、指南宝典、出行攻略等干货内容，毫不吝啬与你分享户外文化，并释出All
                                    Fields对于可持续环保、乡村振兴、潮流时尚等理念的态度和实践。
                                  </div>

                                  <div
                                    class="btns flex-row justify-between"
                                    style="margin-top: 60px"
                                  >
                                    <router-link :to="`/fengxiang/0/${lag}`">
                                      <div
                                        class="lmore flex-row justify-center"
                                        style="
                                          margin-top: 0px;
                                          margin-bottom: 0px;
                                          width: 160px;
                                        "
                                      >
                                        <div
                                          class="lmorein flex-row justify-between"
                                        >
                                          <span class="text-group_123"
                                            >了解更多</span
                                          >

                                          <img
                                            referrerpolicy="no-referrer"
                                            src="https://cdn.moheweb.com/vdb/lweb/imgs/bj.png"
                                            class="image_186 bjt"
                                          />

                                          <img
                                            referrerpolicy="no-referrer"
                                            src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                            class="image_186 wjt"
                                            style="display: none"
                                          />
                                        </div>
                                      </div>
                                    </router-link>
                                  </div>
                                </div>
                              </div>

                              <div class="swiper2bardiv">
                                <span class="sc2index sc2startindex">01</span>
                                <div class="sc2bardiv">
                                  <div class="sc2bar"></div>
                                </div>
                                <span class="sc2index sc2finalindex">{{
                                  lslist.length < 10
                                    ? `0${lslist.length}`
                                    : lslist.length
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="wzd"
                        v-if="
                          winHeight >
                          group1h +
                            group2h +
                            group3h +
                            group4h +
                            group5h +
                            group6h +
                            group7h +
                            4000 +
                            4000 +
                            600
                        "
                        :style="`opacity:${opy7};`"
                      ></div>
                    </div>
                  </div>
                  <div :class="`swiper-slide  ssm ssm6`">
                    <footer-a
                      :kind="`mindex`"
                      :g8top="g8top"
                      :g8left="g8left"
                      :lag="lag"
                    ></footer-a>
                  </div>
                </div>
              </div>
            </div>

            <div class="zzp">
              <img
                class="loadlogo"
                referrerpolicy="no-referrer"
                src="../assets/limgs/logo.png"
              />
              <img class="loading" src="../assets/limgs/loading.png" />
              <div class="backimg"></div>
            </div>
          </div>

          <div class="showlogodiv" v-if="slshow">
            <div
              class="slclose"
              @click="
                () => {
                  slclose();
                }
              "
            ></div>
            <div class="sldiv">
              <div class="ldiv">
                <div class="logoimg">
                  <img :src="baseUrl + flist11[logoindex].imgurl" />
                </div>
                <div class="logoinfo">
                  <div
                    class="logotitle"
                    v-html="flist11[logoindex].title"
                  ></div>
                  <div class="logodes" v-html="flist11[logoindex].des"></div>
                </div>
              </div>
              <div class="ldiv2">
                <div class="title">相关话题</div>
                <div class="line"></div>
                <div class="list">
                  <template v-for="(item, index) in flist11[logoindex].mtipary">
                    <div class="item" :key="index">
                      <div class="t">
                        <a
                          :href="item.link"
                          target="_blank"
                          style="color: #000"
                          >{{ item.tip }}</a
                        >
                      </div>

                      <a :href="item.link" target="_blank" style="color: #000">
                        <img
                          class="jt"
                          referrerpolicy="no-referrer"
                          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng293fdf47784f5f9695e686f2d9fce66c2a06c00689ebab17507b1a05e2617c89"
                        />
                      </a>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import { fetchCase, fetchFriend, fetchBase, checkMember } from "../api/index";
import FooterIndex from "../components/FooterIndex.vue";
// const Base64 = require('js-base64').Base64
import Swiper, {
  FreeMode,
  Autoplay,
  Pagination,
  Mousewheel,
  Scrollbar,
  EffectFade,
  EffectCube,
  Duration,
} from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";
Swiper.use([
  FreeMode,
  Autoplay,
  Pagination,
  Mousewheel,
  Scrollbar,
  EffectFade,
  EffectCube,
  Duration,
]);

import global from "../common";
import $ from "jquery";
// import FooterBar from '../components/FooterBar.vue';
import FooterA from "../components/FooterA.vue";
import TopA from "../components/TopA.vue";
export default {
  name: "index",
  components: {
    FooterIndex,
    FooterA,
    TopA,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      g7selectindex: 0,
      mselectindex: 0,
      bkind: "0",
      bmshow: 0,
      bmstate: false,
      bmid: "0",
      clist90: [],
      clist91: [],
      flist10: [],
      flist11: [],

      clist20: [],

      logoindex: "0",
      slshow: false,
      gglist: [
        {
          url: "https://cdn.moheweb.com/vdb/lweb/imgs/guanggao.png",
        },
        {
          url: "https://cdn.moheweb.com/vdb/lweb/imgs/guanggao.png",
        },
        {
          url: "https://cdn.moheweb.com/vdb/lweb/imgs/guanggao.png",
        },
        {
          url: "https://cdn.moheweb.com/vdb/lweb/imgs/guanggao.png",
        },
      ],
      clist31: [],
      clist33: [],
      data33: null,
      clist40: [],
      lslist: [
        {
          title: "FM.看看世界",
          title2: "出行攻略",
          des: "趣野带你看世界-我们提供新鲜户外资讯，精选音乐/电影/书籍 等兴趣清单，还有各类好物/集合店指南，更多户外生活方式等你来发现！",
          id: "0",
          kind: "00",
          imgurl: "https://cdn.moheweb.com/vdb/lweb/imgs/back00.png",
        },
        {
          title: "趣野故事会",
          title2: "趣野故事会",
          des: "你也喜欢看故事么？此栏目应该很适合你-这里集合户外、任务、品牌故事",
          id: "0",
          kind: "01",
          imgurl: "https://cdn.moheweb.com/vdb/lweb/imgs/back01.png",
        },
        {
          title: "趣野视角",
          title2: "趣野视角",
          des: "带着趣野态度，从原创视角出发，给你带来系列精彩内容：可持续、出行方式、趣路亚、运动旅行、2024奥运会、中国户外文化",
          id: "0",
          kind: "02",
          imgurl: "https://cdn.moheweb.com/vdb/lweb/imgs/back02.png",
        },
      ],
      swiper0backimg: "",

      mdselectIndex: 0,
      splist: [],
      zlist: [],
      pageHeight: 0,
      tbanimate: "down",
      winHeight: 0,
      topbarhide: 0,
      lag: "",
      w750: 0,
      m_group_2_height: 0,
      basedata: [],
      frienddata: [],
      frienddata2: [],
      ppswiperindex: 0,
      nopplistshow: 0,
      baseUrl: global.baseUrl,
      thisclass1: 68,
      thisclass2: "",

      classlist: [],

      class2list: [],

      swiper: null,

      swiper0: null,

      swiper1: null,

      swiper2: null,

      swiper3: null,

      swiper6: null,

      lswiper01: null,
      lswiper0: null,
      lswiper1: null,
      lswiper2: null,
      lswiper3: null,
      lswiper4: null,
      lswiper5: null,
      lswiper6: null,

      selectIndex: 0,

      barclass: "closethis",
      pageWidth: 1920,
      topbarOpacity: 0,
      mkinds: [],
      ppindex: -1,
      pplist: [],
      bselectindex: -1,
      blinks: [
        {
          id: "0",
          title: "趣野风向",
          url: "",
          class: "jianjie",
          list: [
            {
              title: "FM.看看世界",
              des: "了解趣野历史相关活动",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/0bbd5b52752e40d7bd05e634f2ff35a3_mergeImage.png",
              id: "00",
            },
            {
              title: "趣野故事会",
              des: "了解趣野历史相关活动",
              url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngfde342438c247fab8fca0fa5adc4e4889cb2b837a5debc0f0799d0f0f5d3c4c8",
              id: "01",
            },
            {
              title: "趣野视角",
              des: "了解趣野历史相关活动",
              url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng76c7ee71154fce1d4550cbe476a1aa62bd0a0fa0efff11576097faa2e329bc11",
              id: "02",
            },
          ],
        },
        {
          id: "1",
          title: "趣野伙伴",
          url: "",
          class: "huoban",
          list: [
            {
              title: "KOL日常",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/ae86cb0afc6b4b0eb7e811e4cec4908e_mergeImage.png",
              id: "10",
            },
            {
              title: "合作品牌",
              des: "了解更多",
              url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge66575cfe665894eb1ba232fc64948216912902ac68d399dfe1af1df00895add",
              id: "11",
            },
          ],
        },
        {
          id: "2",
          title: "趣野好物（开发中）",
          url: "",
          class: "fuwuneirong",
          list: [
            {
              title: "好物推荐（开发中）",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/90a0e506ec82439ba8b9f3a3b9cafe6f_mergeImage.png",
              id: "20",
            },
            {
              title: "购物平台（开发中）",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/90a0e506ec82439ba8b9f3a3b9cafe6f_mergeImage.png",
              id: "21",
            },
          ],
        },
        {
          id: "3",
          title: "趣野SHOW",
          url: "",
          class: "anli",
          list: [
            {
              title: "现场SHOW什么？",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/90a0e506ec82439ba8b9f3a3b9cafe6f_mergeImage.png",
              id: "30",
            },
            {
              title: "新鲜SHOW闻",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/efed9ea5f9d94fe999ad67d5274954b7_mergeImage.png",
              id: "31",
            },
            {
              title: "Ta们在SHOW",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/cc8ff4aedae34e61931b49962afb8a2c_mergeImage.png",
              id: "32",
            },
            {
              title: "想来SHOW一下么？",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/f5f6ac4ceb9e488f9a8a4bde7d2b358f_mergeImage.png",
              id: "33",
            },
          ],
        },
        {
          id: "4",
          title: "趣野CLUB",
          url: "",
          class: "lianxiwomen",
          list: [
            {
              title: "趣野人集合",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/ae86cb0afc6b4b0eb7e811e4cec4908e_mergeImage.png",
              id: "40",
            },
            {
              title: "精彩回放",
              des: "了解更多",
              url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge66575cfe665894eb1ba232fc64948216912902ac68d399dfe1af1df00895add",
              id: "41",
            },
          ],
        },
      ],
      sectionindex: 0,
      data: [],
      dataloading: 0,
      pageSize: 12,
      query: {
        class1: [],
        class2: [],
      },
      alive: true,
      adata: [],
      awardsindex: 0,

      opy1: 0,
      opy2: 0,
      opy3: 0,
      opy4: 0,
      opy5: 0,
      opy6: 0,
      opy7: 0,
      opy8: 0,

      group1h: 0,
      group2h: 0,
      group3h: 0,
      group4h: 0,
      group5h: 0,
      group6h: 0,
      group7h: 0,

      tb: true,
      masktop: 0,
      masksize: 0,
      g8top: 0,
      g8left: 0,
      wheelTop: 0,
      cl31selectindex: -99,
    };
  },

  activated() {
    let that = this;
    console.log("onActivated");
    let scrollvalue = global.getIndexscroll();
    if (scrollvalue > 0) {
      $(".main").scrollTop(scrollvalue);
    }

    if (scrollvalue > 400) {
      this.topbarOpacity = (scrollvalue - 400) / 200;
      if (this.topbarOpacity > 1) {
        this.topbarOpacity = 1;
      }
    } else {
      this.topbarOpacity = 0;
    }

    that.resizepage();

    setTimeout(() => {
      let classtext = global.getIndexDclass();
      // console.log("classtext:", classtext);
      if (classtext != "") {
        that.goDiv(classtext, "");
      }
      // let video0 = document.getElementById("video0");
      // video0.play();
    }, 1000);

    this.alive = true;
    this.handleScroll;
  },
  deactivated() {
    console.log("deactivated:");
    this.alive = false;
  },

  mounted() {
    const that = this;

    let lag = this.$route.params.lag;

    if (lag) {
      this.lag = lag;
    }

    console.log("this.lag:", this.lag);

    global.setIndexDclass("");
    global.setIndexscroll(0);
    that.handleScroll;
    // that.resizepage();
    if (!that.resizepage()) {
      
      return false;
    }
    // this.$el.addEventListener("scroll", that.handleScroll, true);
    // window.addEventListener("resize", that.handleResize, true);
    window.onresize = () => {
      that.handleResize();
    };

    // that.addwheel();//XG

    that.myswiper();
    that.myswiper1();
    that.myswiper2();
    that.myswiper3();
    setTimeout(() => {
      $(document).on("click", ".closesw", function () {
        $(".showppdiv").fadeTo(300, 0, () => {
          $(".showppdiv").css({ left: "-100%" });
        });
      });
      $(document).on("click", ".closesw3", function () {
        $(".showawardsdiv").fadeTo(300, 0, () => {
          $(".showawardsdiv").css({ left: "-100%" });
        });
      });
    }, 1000);

    that.fetch40();
    that.fetch31();
    that.fetch33();
    that.fetch10();
    that.fetch11();

    that.fetch20();

    that.fetch90();
    that.fetch91();

    that.fetchb();

    // alert(`https://www.all-fields.com/api/share/?gourl=${ encodeURIComponent('https://www.all-fields.com/#/mdetall/33/34/') }&code=900000001`);
  },
  beforeDestroy() {
    window.onresize = null;
    console.log("index beforeDestroy");
  },
  beforeCreate() {
    const that = this;
  },
  methods: {
    clist31itemover(index) {
      console.log(index);
      this.cl31selectindex = index;
    },
    clist31itemleave() {
      this.cl31selectindex = -99;
    },
    takeprice(price) {
      let pstr = "";
      if (price.split(".").length > 1) {
        if (price.split(".")[1].length < 2) {
          pstr = "0";
          return String(price) + pstr;
        }
      } else {
        return String(price) + ".00";
      }
    },
    g7itemover(index) {
      this.g7selectindex = index;
    },
    addwheel() {
      // let that = this;
      // var dom = document.querySelector(".page");
      // if (window.addEventListener) {
      //   dom.addEventListener("wheel", that.loadWheel);
      // } else {
      //   dom.attachEvent("onmousewheel", that.loadWheel);
      // }
    },

    removewheel() {
      // let that = this;
      // var dom = document.querySelector(".page");
      // if (window.addEventListener) {
      //   dom.removeEventListener("wheel", that.loadWheel);
      // }
    },

    handleScroll2() {
      if (this.alive) {
        let winHeight = this.wheelTop * -1;
        global.setIndexscroll(winHeight);
        // console.log({top:this.wheelTop+"px"});
        $(".page").css({ top: this.wheelTop + "px" });
        this.recountHsize(winHeight);
        // console.log("Scroll:", winHeight);
      }
    },

    loadWheel(e) {
      // e.preventDefault();
      const that = this;
      that.wheelTop += e.wheelDelta * 0.4;

      if (that.wheelTop > 0) {
        that.wheelTop = 0;
      }
      if (that.wheelTop < $(".page").height() * -1 + window.innerHeight) {
        that.wheelTop = $(".page").height() * -1 + window.innerHeight;
      }

      that.handleScroll2();
    },

    bmopen() {
      this.bmshow = 1;
      this.bmstate = true;
      this.bmid = "0";
      this.bkind = "0";
      this.removewheel();
      console.log("this.bmshow:", this.bmshow);
    },
    bmclose(id) {
      this.addwheel();
      console.log("bmclose");
      this.bmshow = 0;
      this.bmstate = true;
      this.bmid = id;

      console.log("this.bmshow:", this.bmshow);
    },
    rlstate(state) {
      if (state == 1) {
        this.removewheel();
      } else if (state == 0) {
        this.addwheel();
      }
    },
    godetall(link, title, kind, id) {
      // alert(title+link);
      if (link != "") {
        window.location.href = link;
      } else {
        if (title != "") {
          this.$router.push(`/mdetall/${kind}/${id}/${this.lag}`);
        }
      }
    },
    slclose() {
      this.slshow = false;
    },
    setlogoindex(index) {
      this.logoindex = index;
      this.slshow = true;
    },

    fetch10(
      params = { show: 1, hot: 1, kind: "10", pagesize: 999, query: this.query }
    ) {
      let that = this;
      fetchFriend(params).then((res) => {
        if (res.results.list) {
          that.flist10 = res.results.list;
        }
        console.log("flist10:", that.flist10);
      });
    },
    fetch11(
      params = { show: 1, hot: 1, kind: "11", pagesize: 999, query: this.query }
    ) {
      let that = this;
      fetchFriend(params).then((res) => {
        if (res.results.list) {
          that.flist11 = res.results.list;
        }
        console.log("flist11:", that.flist11);
      });
    },

    swiper1Prev() {
      this.swiper1.slidePrev(500);
    },
    swiper1Next() {
      this.swiper1.slideNext(500);
    },

    windowopen(url) {
      if (url != "") {
        window.open(url);
      }
    },
    fetch20(
      params = { show: 1, hot: 1, kind: "20", pagesize: 999, query: this.query }
    ) {
      let that = this;
      fetchCase(params).then((res) => {
        if (res.results.list) {
          that.clist20 = res.results.list;
        }
      });
    },

    fetch90(
      params = { show: 1, hot: 1, kind: 90, pagesize: 99, query: { mobile: 0 } }
    ) {
      let that = this;
      fetchCase(params).then((res) => {
        if (res.results.list) {
          that.clist90 = res.results.list;
        }
        console.log("clist90:", that.clist90);

        setTimeout(() => {
          that.lswiper01 = new Swiper(".lsc-1", {
            //子swiper
            direction: "vertical",
            nested: true,
            resistanceRatio: 0,
            slidesPerView: "auto",
            freeMode: true,
            mousewheel: {
              sensitivity: 0.7,
              // thresholdTime: 1000,
            },
            on: {
              setTransition: function () {},
              transitionStart: function (swiper) {
                console.log(swiper.translate);
              },
            },
          });
        }, 10);

        that.myswiper0();
      });
    },

    p6animate(thispindex) {
      console.log(
        "thispindex:",thispindex
      )
      if (thispindex > 520) {
        $(".p6pi1").addClass("p6pi1animate");
      }
      if (thispindex < 300) {
        $(".p6pi1").removeClass("p6pi1animate");
      }
      if (thispindex > 600) {
        $(".text_49").addClass("text_49animate");
      }
      if (thispindex < 300) {
        $(".text_49").removeClass("text_49animate");
      }
      if (thispindex > 620) {
        $(".p6pi2").addClass("p6pi2animate");
      }
      if (thispindex < 300) {
        $(".p6pi2").removeClass("p6pi2animate");
      }
      if (thispindex > 600) {
        $(".p6pi3").addClass("p6pi3animate");
      }
      if (thispindex < 300) {
        $(".p6pi3").removeClass("p6pi3animate");
      }

      if (thispindex > 1200) {
        $(".p6pi4").addClass("p6pi4animate");
      }
      if (thispindex < 300) {
        $(".p6pi4").removeClass("p6pi4animate");
      }
      if (thispindex > 1400) {
        $(".p6pi5").addClass("p6pi5animate");
      }
      if (thispindex < 300) {
        $(".p6pi5").removeClass("p6pi5animate");
      }

      if (thispindex > 1300) {
        $(".g6btitle").addClass("g6btitleanimate");
      }
      if (thispindex < 500) {
        $(".g6btitle").removeClass("g6btitleanimate");
      }

      if (thispindex > 1300) {
        $(".logolist").addClass("logolistanimate");
      }
      if (thispindex < 500) {
        $(".logolist").removeClass("logolistanimate");
      }
    },

    fetch91(
      params = { show: 1, hot: 1, kind: 91, pagesize: 99, query: this.query }
    ) {
      let that = this;
      fetchCase(params).then((res) => {
        if (res.results.list) {
          that.clist91 = res.results.list;
        }
        console.log("clist91:", that.clist91);
        that.myswiper6();

        setTimeout(() => {
          that.lswiper2 = new Swiper(".lsc2", {
            //子swiper
            direction: "vertical",
            nested: true,
            resistanceRatio: 0,
            slidesPerView: "auto",
            freeMode: true,

            mousewheel: {
              sensitivity: 0.7,
              // thresholdTime: 1000,
            },
            on: {
              touchMove: function (swiper, event) {
                let thispindex = that.lswiper2.getTranslate() * -1;
                that.p6animate(thispindex+that.pageHeight);
              },
              setTransition: function () {
                let thispindex = that.lswiper2.getTranslate() * -1;
                that.p6animate(thispindex+that.pageHeight);
              },
              transitionStart: function (swiper) {
                console.log(swiper.translate);
              },
            },
            // scrollbar: {
            //   el: ".swiper-scrollbar",
            // },
          });
        }, 10);
      });
    },

    fetch40(
      params = { show: 1, hot: 1, kind: 40, pagesize: 3, query: this.query }
    ) {
      let that = this;
      fetchCase(params).then((res) => {
        if (res.results.list) {
          that.clist40 = res.results.list;
        }
        console.log("clist40:", that.clist40);

        setTimeout(() => {
          this.lswiper0 = new Swiper(".lsc0", {
            //子swiper
            direction: "vertical",
            nested: true,
            resistanceRatio: 0,
            slidesPerView: "auto",
            freeMode: true,

            mousewheel: {
              sensitivity: 0.7,
              thresholdTime: 1000,
            },
            on: {
              setTransition: function () {
                let thispindex =
                  parseInt(that.lswiper0.getTranslate() / (800 / 7)) * -1;
                // console.log("thispindex:", thispindex);
                $(".zsqitem").removeClass("zsqselected");
                $(".zi" + thispindex).addClass("zsqselected");
              },
              transitionStart: function (swiper) {
                // console.log(swiper.translate);
              },
            },
            // scrollbar: {
            //   el: ".swiper-scrollbar",
            // },
          });
        }, 10);
      });
    },

    fetch31(
      params = { show: 1, hot: 1, kind: 31, pagesize: 6, query: this.query }
    ) {
      let that = this;
      fetchCase(params).then((res) => {
        if (res.results.list) {
          that.clist31 = res.results.list;
        }
        console.log("clist31:", that.clist31);

        setTimeout(() => {
          this.lswiper1 = new Swiper(".lsc1", {
            //子swiper
            direction: "vertical",
            nested: true,
            resistanceRatio: 0,
            slidesPerView: "auto",
            freeMode: true,

            mousewheel: {
              sensitivity: 0.7,
              // thresholdTime: 1000,
            },
            on: {
              setTransition: function () {
                let thispindex =
                  parseInt(that.lswiper1.getTranslate() / (800 / 7)) * -1;
                // console.log("thispindex:", thispindex);
                $(".zsqitem").removeClass("zsqselected");
                $(".zi" + thispindex).addClass("zsqselected");
              },
              transitionStart: function (swiper) {
                console.log(swiper.translate);
              },
            },
            // scrollbar: {
            //   el: ".swiper-scrollbar",
            // },
          });
        }, 500);
      });
    },
    fetch33(
      params = { show: 1, hot: "", kind: 33, pagesize: 1, query: this.query }
    ) {
      let that = this;
      fetchCase(params).then((res) => {
        if (res.results.list) {
          that.clist33 = res.results.list;
          if (that.clist33.length > 0) {
            that.data33 = that.clist33[0];
          }
        }
        console.log("clist33:", that.clist33);
      });
    },
    fetch(
      params = { show: 1, hot: 1, pagesize: this.pageSize, query: this.query }
    ) {
      this.ppswiperindex = 0;
      let that = this;
      this.loading = true;

      fetchCase(params).then((res) => {
        // console.log("fetchCase:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        that.loading = false;

        if (res.results.list) {
          that.data = res.results.list;
          that.nopplistshow = 0;
        } else {
          that.nopplistshow = 1;
        }
        that.dataloading = 0;
        that.pagination = pagination;
      });
    },
    fetchf(params = { show: 1, pagesize: 1000 }) {
      let that = this;
      this.loading = true;

      fetchFriend(params).then((res) => {
        // console.log("fetchFriend:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        that.loading = false;

        that.frienddata = res.results.list;

        let frienddata2 = [];

        let lindex = 0;
        res.results.list.map((item, index) => {
          if (index % 4 == 0) {
            frienddata2[lindex] = [];
          }
          frienddata2[lindex].push(item);
          if (index % 4 == 3) {
            lindex++;
          }
        });
        that.frienddata2 = frienddata2;

        if (that.w750 == 0) {
          that.myswiper2();
        } else {
          that.myswiper2m();
        }
      });
    },
    fetchb(params = {}) {
      let that = this;
      this.loading = true;

      fetchBase({}).then((res) => {
        // console.log("fetchBase:", res.results);
        that.loading = false;
        that.basedata = res.results;
      });
    },

    selectclass2(id) {
      this.nopplistshow = 0;
      this.dataloading = 1;
      this.thisclass2 = id;
      // console.log(id);
      this.query = {
        class1: this.thisclass1,
        class2: [id],
      };
      this.data = [];
      setTimeout(() => {
        this.fetch();
      }, 500);
    },

    showdetall(kind, id) {
      this.$router.push(`/mdetall/${kind}/${id}/${this.lag}`);
    },

    swSlideTo(index, id) {
      // alert(this.w750);

      if (this.w750 == 1) {
        if (this.lag == "en") {
          this.$router.push(`/pdetall/${d}/en`);
        } else {
          this.$router.push(`/pdetall/${id}`);
        }
        return false;
      }
      let that = this;
      this.swiper.slideTo(index, 0);
      $(".showppdiv").css({ left: "0%" });
      $(".showppdiv").fadeTo(300, 1, () => {});
    },

    sw3SlideTo(index, id) {
      console.log(index, id);

      if (this.w750 == 1) {
        if (this.lag == "en") {
          this.$router.push(`/adetall/${d}/en`);
        } else {
          this.$router.push(`/adetall/${id}`);
        }
        return false;
      }
      let that = this;
      that.swiper3.slideTo(index, 0);
      $(".showawardsdiv").css({ left: "0%" });
      $(".showawardsdiv").fadeTo(300, 1, () => {});
    },
    gowebsite(url) {
      window.open(url);
    },
    myswiper() {
      const that = this;

      setTimeout(() => {
        that.swiper = new Swiper(".sc", {
          speed: 1000,
          // effect: "fade",
          direction: "vertical",
          // freeMode: true,
          mousewheel: {
            sensitivity: 0.5,
            thresholdTime: 1000,
          },
          // mousewheel: {
          //   thresholdTime: 2000,
          // },
          releaseOnEdges: true,
          // resistanceRatio: 0,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          observeSlideChildren: true,
          on: {
            transitionStart: function (swiper) {
              // console.log("transitionStart");

              console.log("transitionStart", this.activeIndex);
              $(`.ssm`).removeClass("smmanimate");
              console.log(`.ssm${this.activeIndex}`);
              $(`.ssm${this.activeIndex}`).addClass("smmanimate");
              // if(this.activeIndex>3){
              //   that.swiper.effect = 'fade';
              //   that.swiper.update();
              // }else{
              //   that.swiper.effect = 'slide';
              //   that.swiper.update();
              // }

              that.masksize = 300;
              if (that.lswiper01) {
                that.lswiper01.mousewheel.disable();
                that.lswiper0.mousewheel.disable();
                that.lswiper1.mousewheel.disable();
                that.lswiper2.mousewheel.disable();
              }
            },
            transitionEnd: function () {
              that.mselectindex = this.activeIndex;
              // alert(that.mselectindex);
              if (that.mselectindex == 0) {
                that.tb = true;
              }
              if (that.mselectindex > 0) {
                that.tb = false;
              }
              if (that.lswiper01) {
                that.lswiper01.mousewheel.enable();
                that.lswiper0.mousewheel.enable();
                that.lswiper1.mousewheel.enable();
                that.lswiper2.mousewheel.enable();
              }
            },
            init: function (swiper) {
              this.emit("transitionEnd");
            },
          },
        });

        // setTimeout(() => {
        //   that.swiper.slideTo(2, 1000);
        // }, 4000);
      }, 10);
    },

    myswiper0() {
      let that = this;

      $(".sc0zb").show();
      that.swiper0backimg =
        that.baseUrl + that.clist90[that.clist90.length - 1].imgurl;
      setTimeout(() => {
        that.swiper0backimg = that.baseUrl + that.clist90[0].imgurl;
        $(".ss0 .mask").removeClass("mkmaskshow");
        $(".sc0zb").hide();
      }, 2000);

      setTimeout(() => {
        this.swiper0 = new Swiper(".sc0", {
          effect: "fade",
          speed: 1000,
          autoplay: {
            delay: 6000,
            disableOnInteraction: false,
          },
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          observeSlideChildren: true,
          init: function (swiper) {},
          on: {
            slideChange: function () {
              $(".sc0zb").show();
              $(".ss" + this.activeIndex + " .mk0").addClass("mkmaskshow");
              that.mdselectIndex = this.activeIndex;
              let thisimgurl = that.clist90[this.activeIndex].imgurl;
              setTimeout(() => {
                $(".sc0 .mask").removeClass("mkmaskshow");
                that.swiper0backimg = that.baseUrl + thisimgurl;
                $(".sc0zb").hide();
              }, 2000);
            },
          },
        });

        $(".sc0").css({ opacity: "1" });
      }, 10);
    },

    myswiper1() {
      let that = this;

      setTimeout(() => {
        this.swiper1 = new Swiper(".sc1", {
          slidesPerView: 3,
          // slidesPerView: 2,
          // loop: true,
          centeredSlides: true,
          spaceBetween: 0,
          grabCursor: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          observeSlideChildren: true,

          on: {
            transitionEnd: function () {
              that.selectIndex = this.activeIndex;
              $(`.sc1 .swiper-slide`).removeClass("ssselected");
              $(`.ss${this.activeIndex}`).addClass("ssselected");
            },
            init: function (swiper) {
              // this.emit("transitionEnd"); //在初始化时触发一次transitionEnd事件，需要先设置transitionEnd
            },
          },
        });
      }, 10);
    },
    myswiper3() {},

    myswiper6() {
      let that = this;

      setTimeout(() => {
        this.swiper6 = new Swiper(".sc6", {
          // autoHeight: true,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          observeSlideChildren: true,

          pagination: {
            el: ".sp6",
            clickable: true,
          },
        });
      }, 500);
    },

    myswiper2() {
      let that = this;

      setTimeout(() => {
        this.swiper2 = new Swiper(".sc2", {
          slidesPerView: 3,
          // slidesPerView: 2,
          // loop: true,
          centeredSlides: true,
          spaceBetween: 0,
          grabCursor: true,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          observeSlideChildren: true,

          // pagination: {
          //   el: ".swiper-pagination",
          //   clickable: true,
          // },

          on: {
            transitionEnd: function () {
              // that.selectIndex = this.activeIndex;

              // console.log("this", this);

              // console.log("this.selectIndex:", this.realIndex);
              let w =
                parseInt(
                  ((parseInt(this.realIndex) + 1) / that.lslist.length) * 1000
                ) / 10;
              // console.log("www:", w);
              $(".sc2bar").css({ width: w + "%" });
            },
            init: function (swiper) {
              let w = parseInt((1 / that.lslist.length) * 1000) / 10;
              $(".sc2bar").css({ width: w + "%" });
            },
          },
        });
      }, 10);
    },

    myswiper2m() {},

    openBardiv() {
      this.barclass = "openthis";
    },
    closeBardiv() {
      this.barclass = "closethis";
    },
    handleResize() {
      this.resizepage();
    },

    resizeViewport() {
      let that = this;
      let bl = window.innerHeight / window.innerWidth;
      if (bl > 1.2) {
        this.sectionindex = 0;
        this.m_group_2_height = this.pageHeight;
        this.w750 = 1;
        if (bl > 1.5) {
          $("meta[name=viewport]").attr(
            "content",
            "width=750,user-scalable=no"
          );
        } else {
          $("meta[name=viewport]").attr(
            "content",
            "width=1280,user-scalable=no"
          );
        }
        this.$router.replace(`/indexm/${this.lag}`);
        // window.location.href=`#/indexm/${this.lag}`
        // setTimeout(() => {
        //   window.location.reload();
        // }, 500);
        return false;
      } else {
        $("meta[name=viewport]").attr("content", "width=1280,user-scalable=no");
        this.m_group_2_height = 0;
        this.w750 = 0;
        // window.location.href = `#/index/${this.lag}`;
      }

      setTimeout(() => {
        $(".zzp").fadeTo(1000, 0, function () {
          $(".zzp").hide();
        });
      }, 1500);
      return true;
    },

    resizepage() {
      let that = this;
      this.pageWidth = window.innerWidth;
      let pageHeight = window.innerHeight;

      this.pageHeight = pageHeight;

      $(".windowheight").css({ height: `${this.pageHeight}px` });
      setTimeout(() => {
        $(".windowheight").css({ height: `${this.pageHeight}px` });
      }, 1000);

      $("html").css({ fontSize: this.pageWidth / 192 });

      console.log("pageWidth:", this.pageWidth);
      console.log("fontSize:", this.pageWidth / 192);

      return this.resizeViewport();

      // this.recountHsize(this.winHeight); //XG
    },
    handleScroll(e) {
      // console.log("handleScroll");
      e.preventDefault();

      if (this.alive) {
        const winHeight =
          e.target.scrollTop || document.documentElement.scrollTop;
        console.log("e.target.scrollTop:", e.target.scrollTop);
        global.setIndexscroll(winHeight);

        // this.recountHsize(winHeight); //XG

        // console.log("Scroll:", winHeight);
      }
    },
    recountHsize(winHeight) {
      let group1h = $(".group_1").height();
      let group2h = $(".group_2").height();
      let group3h = $(".group_3").height();
      let group4h = $(".group_4").height();
      let group5h = $(".group_5").height();
      let group6h = $(".group_6").height();
      let group7h = $(".group_7").height();
      this.group1h = group1h;
      this.group2h = group2h;
      this.group3h = group3h;
      this.group4h = group4h;
      this.group5h = group5h;
      this.group6h = group6h;
      this.group7h = group7h;

      this.tb = true;

      if (
        this.winHeight >
        this.pageHeight +
          this.pageHeight +
          this.pageHeight +
          group2h +
          group4h +
          group6h +
          2000 +
          2000
      ) {
        console.log("down");
        this.tbanimate = "up";
        // $(".topbar").stop(true).animate({ top: "-140px" }, 100);
        $(".topbar").removeClass("downtopbar");
        $(".topbar").addClass("uptopbar");
      }

      if (winHeight > 120) {
        if (this.winHeight > winHeight && this.tbanimate != "down") {
          this.tbanimate = "down";
          // $(".topbar").stop(true).animate({ top: "0px" }, 100);
          $(".topbar").removeClass("uptopbar");
          $(".topbar").addClass("downtopbar");
        } else if (this.winHeight < winHeight && this.tbanimate != "up") {
          this.tbanimate = "up";
          // $(".topbar").stop(true).animate({ top: "-140px" }, 100);
          $(".topbar").removeClass("downtopbar");
          $(".topbar").addClass("uptopbar");
        }
      }

      this.winHeight = winHeight;

      if (winHeight > 400) {
        this.topbarOpacity = (winHeight - 400) / 200;
        if (this.topbarOpacity > 1) {
          this.topbarOpacity = 1;
        }
      } else {
        this.topbarOpacity = 0;
      }

      if (winHeight >= group1h) {
        $(".group_2").css({
          position: "absolute",
          top: group1h + (winHeight - group1h) / 2 + "px",
        });
        $(".group_3").css({
          position: "absolute",
          top: group1h + group2h + (winHeight - group1h) / 2 + "px",
        });
      } else {
        $(".group_2").css({
          position: "absolute",
          top: group1h + "px",
        });
        $(".group_3").css({
          position: "absolute",
          top: group1h + group2h + "px",
        });
      }

      if (winHeight >= group1h / 4) {
        this.opy1 = (winHeight - group1h / 4) / (group1h / 2);
      } else if (winHeight < group1h / 4) {
        this.opy1 = 0;
      }

      if (
        this.winHeight > group1h + group2h + group2h &&
        this.winHeight < group1h + group2h + 4000
      ) {
        let ystart = parseInt($(".m0")[0].dataset.ystart);
        let yfinal = parseInt($(".m0")[0].dataset.yfinal);
        let dur = parseInt($(".m0")[0].dataset.dur);

        this.tb = false;

        $(".group_3").css({
          position: "absolute",
          top: this.winHeight + "px",
        });

        let thispindex = parseInt(
          ((this.winHeight - (group1h + group2h + group2h)) /
            (4000 - group2h)) *
            7
        );

        console.log("thispindex:", thispindex);

        $(".zsqitem").removeClass("zsqselected");

        $(".zi" + thispindex).addClass("zsqselected");

        let mtop = this.winHeight - (group1h + group2h + group2h);
        // console.log("mtop:", mtop);
        let top = ystart + ((yfinal - ystart) * mtop) / dur;
        // console.log("top:", top);

        $(".m0").css({ top: top + "px" });
      }
      if (
        this.winHeight > group1h + group2h + 4000 &&
        this.winHeight < group1h + group2h + 4000 + this.pageHeight + 1000
      ) {
        $(".group_3").css({
          position: "absolute",
          top: group1h + group2h + 4000 + "px",
        });

        this.opy3 = (winHeight - (group1h + group2h + 4000)) / this.pageHeight;
        // console.log("this.opy3:", this.opy3);
      }
      if (this.winHeight < group1h + group2h + 4000) {
        this.opy3 = 0;
      }

      if (this.winHeight > group1h + group2h + 4000 + this.pageHeight + 1000) {
        this.opy3 = 0;
      }

      // if (winHeight >= group1h + group2h + group3h + 3500) {
      //   this.opy3 = (winHeight - (group1h + group2h + group3h + 3500)) / 1000;
      // }

      //group_4 动画

      if (this.winHeight < group1h + group2h + group3h + 4000) {
        $(".group_4").css({
          position: "absolute",
          top: group1h + group2h + group3h + 4000 + "px",
        });
      }

      if (this.winHeight > group1h + group2h + group3h + 4000) {
        $(".group_4").css({
          position: "absolute",
          top:
            group1h +
            group2h +
            group3h +
            4000 +
            (winHeight - group1h - group2h - group3h - 4000) / 2 +
            "px",
        });
      }

      //group_5 动画

      if (this.winHeight < group1h + group2h + group3h + group4h + 4000) {
        $(".group_5").css({
          position: "absolute",
          top: group1h + group2h + group3h + group4h + 4000 + "px",
        });
      }

      if (
        this.winHeight > group1h + group2h + 4000 + 4000 &&
        this.winHeight < group1h + group2h + 4000 + 4000 + 2000
      ) {
        if (
          this.winHeight > group1h + group2h + 4000 + 4000 &&
          this.winHeight < group1h + group2h + 4000 + 4000 + 500
        ) {
          this.swiper1.slideTo(0, 500);
        }

        if (
          this.winHeight > group1h + group2h + 4000 + 4000 + 500 &&
          this.winHeight < group1h + group2h + 4000 + 4000 + 1000
        ) {
          this.swiper1.slideTo(1, 500);
        }

        if (
          this.winHeight > group1h + group2h + 4000 + 4000 + 1000 &&
          this.winHeight < group1h + group2h + 4000 + 4000 + 1500
        ) {
          this.swiper1.slideTo(2, 500);
        }

        if (
          this.winHeight > group1h + group2h + 4000 + 4000 + 1500 &&
          this.winHeight < group1h + group2h + 4000 + 4000 + 2000
        ) {
          this.swiper1.slideTo(3, 500);
        }
      }

      if (this.winHeight > group1h + group2h + group3h + group4h + 4000) {
        // if (
        //   this.winHeight > group1h + group2h + group3h + group4h + 4000 &&
        //   this.winHeight < group1h + group2h + group3h + group4h + 4000 + 4000
        // ) {

        $(".group_5").css({
          position: "absolute",
          top: this.winHeight + "px",
        });
        this.tb = false;
      }
      // if (
      //   this.winHeight >
      //   group1h + group2h + group3h + group4h + 4000 + 4000
      // ) {
      //   $(".group_5").css({
      //     position: "absolute",
      //     top: group1h + group2h + group3h + group4h + 4000 + 4000 + "px",
      //   });
      // }

      //group_6 动画

      if (
        this.winHeight <
        group1h + group2h + group3h + group4h + 4000 + 4000
      ) {
        $(".group_6").css({
          position: "absolute",
          top:
            this.pageHeight +
            this.pageHeight +
            this.pageHeight +
            group2h +
            group4h +
            4000 +
            4000 +
            "px",
        });
      }

      let bheight = group1h + group2h + group3h + group4h + 4000 + 3700; ///*************************
      if (this.winHeight > bheight) {
        $(".group_6").css({
          position: "absolute",
          top: this.winHeight + "px",
        });

        this.opy5 = (this.winHeight - bheight) / 1200;

        // this.masktop = 30-((this.winHeight-bheight)/1000) * 1000;
        this.masksize = ((this.winHeight - bheight) / 1000) * 250;
        if (this.masksize > 300) {
          this.masksize = 300;
        }
      }
      bheight = group1h + group2h + group3h + group4h + 4000 + 4000 + 1200; ///*************************
      if (this.winHeight > bheight) {
        $(".group_6").css({
          position: "absolute",
          top: bheight + "px",
        });
      }

      bheight = group1h + group2h + group3h + group4h + group6h + 4000 + 4300; ///*************************

      if (this.winHeight > bheight) {
        this.opy6 = (this.winHeight - bheight) / 600;
      } else {
        this.opy6 = 0;
      }

      //group_7 动画

      bheight =
        group1h + group2h + group3h + group4h + group6h + 4000 + 4000 + 1200; ///*************************
      if (this.winHeight < bheight) {
        // console.log(".group_7");
        $(".group_7").css({
          position: "absolute",
          top:
            group1h +
            group2h +
            group3h +
            group4h +
            group5h +
            group6h +
            4000 +
            4000 +
            200 +
            "px",
        });
      }

      bheight =
        group1h + group2h + group3h + group4h + group6h + 4000 + 4000 + 1200; ///*************************

      if (this.winHeight > bheight) {
        // console.log(".group_7");
        $(".group_7").css({
          position: "absolute",
          top: this.winHeight + "px",
        });
      }

      // //group_8 动画

      bheight =
        group1h +
        group2h +
        group3h +
        group4h +
        group5h +
        group6h +
        group7h +
        4000 +
        4000 +
        600; ///*************************

      if (this.winHeight > bheight) {
        $(".group_8").css({
          position: "absolute",
          top: this.winHeight + "px",
        });

        // console.log("123321123321");

        this.opy7 = (this.winHeight - bheight) / 600;

        this.g8top = (-2400 * (this.winHeight - bheight)) / 1200;
        this.g8left = (-2400 * (this.winHeight - bheight)) / 1200;
      }
      if (this.winHeight < bheight) {
        $(".group_8").css({
          top: "auto",
          bottom: "-200%",
        });
      }

      // if (
      //   this.winHeight <
      //   group1h +
      //     group2h +
      //     group3h +
      //     group4h +
      //     group5h +
      //     group6h +
      //     group7h +
      //     4000 +
      //     4000
      // ) {
      //   $(".group_8").css({
      //     position: "absolute",
      //     top:
      //       group1h +
      //       group2h +
      //       group3h +
      //       group4h +
      //       group5h +
      //       group6h +
      //       group7h +
      //       4000 +
      //       4000 +
      //       "px",
      //   });
      // }

      // if (
      //   group1h +
      //   group2h +
      //   group3h +
      //   group4h +
      //   group5h +
      //   group6h +
      //   group7h +
      //   4000 +
      //   4000
      // ) {
      //   // console.log("this.winHeight:", this.winHeight);
      //   $(".group_8").css({
      //     position: "absolute",
      //     top: this.winHeight + "px",
      //   });
      // }

      $(".page").css({
        height:
          this.pageHeight +
          group1h +
          group2h +
          group3h +
          group4h +
          group5h +
          group6h +
          group7h +
          4000 +
          4000 +
          1800 +
          "px",
      });

      // console.log(winHeight);
    },
    gotourl(url, kind) {
      this.$router.push(`/${url}/${kind}`);
    },

    goDiv(classtext, url) {
      this.barclass = "closethis";
      if (url != "") {
        global.setIndexscroll(0);
        if (this.lag == "en") {
          url = `/${url}/en`;
        }
        this.$router.push(url);
        return false;
      }
      // 获取元素在页面中的位置
      // console.log(this.$refs.dingyue_box_ref.getBoundingClientRect());

      // document
      //   .getElementById("agentTitle")
      //   .scrollIntoView({ block: "start", behavior: "smooth" });
      // 跳转到指定位置并且平滑滚动
      // console.log(this.$el.querySelector(`.${classtext}`));
      this.$el
        .querySelector(`.${classtext}`)
        .scrollIntoView({ block: "start", behavior: "smooth" });

      global.setIndexDclass("");
    },

    sectionhandleHover(index) {
      if (this.w750 == 0) this.sectionindex = index;
    },
    pphandleHover(index) {
      this.ppindex = index;
    },
    pphandleLeave() {},

    awardshandleHover(index) {
      this.awardsindex = index;
    },
    awardshandleLeave() {
      this.awardsindex = -1;
    },
  },
};
</script>


<style src="../assets/css/common.css" />
<style src="../assets/css/indexl.css" />
<style src="../assets/css/indexl1280.css" />
<style src="../assets/css/indexlxg.css" />

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
